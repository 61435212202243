import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

var $j = jQuery.noConflict();



$j(document).on("scroll", function() {

    if($j(document).scrollTop()>100) {
        $j("header").removeClass("large").addClass("small");
    } else {
        $j("header").removeClass("small").addClass("large");
    }

});

// Schaltet mir die 100vh fullheight Navbar aus sobald ich geklickt habe

$j(document).ready(function($) {
    $j('#hauptmenu li').click(function() {
        if(Foundation.MediaQuery.current == 'small'){
            $j('#hauptmenu').css('display', 'none');
        }
    });
});

$j(document).ready(function($) {
    $j('#hauptmenu li').click(function() {
        if(Foundation.MediaQuery.current == 'medium'){
            $j('#hauptmenu').css('display', 'none');
        }
    });
});


// aktiviert mir die Klasse «clicked» beim geklickten Punkt und geht wieder weg wenn ich einen zweiten Punkt anklicke


$j('#hauptmenu li a').on('click', function(){
    $j('#hauptmenu li a.clicked').removeClass('clicked');
    $j(this).addClass('clicked');
});


